/*
 * 업무구분: 영업
 * 화 면 명: MSPFS317M
 * 화면설명: 비밀번호오류횟수초기화1610
 * 작 성 일: 2023.01.16
 * 작 성 자: 이지수
 */
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">
    <!-- header start -->
    <fs-header
      ref="fsHeader"
      :propObj="pHeaderObj"
    ></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left gap40">
            <div class="wrap-input row">
              <label class="emphasis"> 고객번호 </label>
              <mo-text-field type="number" 
              maxlength="7"
              v-model="ds_head.cust_no" 
              @keyup="fn_CustInfo($event)" class="w150" ref="custNo" />
              <mo-button class="w61" @click="fn_OpenPopup"> 고객정보 </mo-button>
              <mo-text-field v-model="ds_head.cust_nm" class="w130" disabled />
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button @click="fn_init"> 초기화 </mo-button>
              <mo-button primary @click="fn_searchList"> 조회 </mo-button>
            </div>
          </div>
        </div>
      </ur-box-container>

      <!-- fs-cust-info start -->
      <fs-cust-info
        ref="fsCustInfo"
        :propObj="pCustInfoObj"
        @fs-cust-name="fn_SetEdCustName"
      ></fs-cust-info>
      <!-- fs-cust-info end -->
      
      <ur-box-container direction="column" class="mt15" alignV="start">
        <div class="wrap-table h-scroll">
          <table class="table row-type col20">
            <thead>
              <th>그룹번호</th>
              <th>브릿지계좌(예수금)잔고</th>
              <th>계좌수</th>
              <th>거래여부</th>
              <th>비밀번호오류횟수</th>
            </thead>
            <tbody>
              <tr v-for="(row, i) in ds_detail" :key="i">
                <td> {{fn_acctNoDashFormat(row.pbk_no)}} </td>
                <td class="aR"> {{$bizUtil.numberWithCommas(row.depo_orign_amt)}} </td>
                <td class="aR"> {{row.acct_cnt}} </td>
                <td> {{row.int_tr_yn}} </td>
                <td class="aR"> {{row.pwd_err_cnt}} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" @click="fn_errCntClear"> 오류횟수초기화 </mo-button>
        </div>
      </ur-box-container>

    </main>
    <!-- //.fts-main -->
    <!-- alert modal popup -->
    <fs-alert-popup
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>

    <!-- popup104 -->
    <msp-fs-308p
      ref="popup308"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup308Obj"
    ></msp-fs-308p>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
  import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'
  import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'

  import FSHeader from '~/src/ui/fs/comm/FSHeader'          // header 영역 (공통)
  import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)
  import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo'      // 고객정보 (공통)

  import MSPFS308P from '~/src/ui/fs/MSPFS308P'  // 고객정보 팝업
  

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS317M",
    screenId: "MSPFS317M",
    components: {
      'fs-header': FSHeader,
      'fs-alert-popup': FSAlertPopup,
      'fs-cust-info': FSCustInfo,
      'msp-fs-308p': MSPFS308P,
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     **************
     *********************************************************************/
    created() {},
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)
      this.fn_init();
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 공통 객체
        pHeaderObj: {
          title: '비밀번호 오류횟수 초기화',
          step: '',
        },
        eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
        pAlertPopupObj: {},
        lv_commCode: {},
        pCustInfoObj: {},
        //popup
        pPopup308Obj: {},
        // 계좌정보
        lv_cust_info: {},           // 고객정보
        lv_acct_list: [],           // 계좌조회목록

        // 고객정보 펼치기/접기
        isActive: false,

        //dataset 
        ds_detail : [
          // {
          //   pbk_no : '',
          //   cust_nm : '',
          //   depo_orign_amt : '',
          //   acct_cnt : '',
          //   int_tr_yn : '',
          //   pwd_err_cnt : ''
          // }
        ],
        ds_head : {
          proc_gb : '',
          cust_no : '',
          cust_nm : '',
          depo_fund_cd : '',
          sup_id : '',
          aprv_sno : ''
        },

        lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
        //as is 전역변수 todo.. userinfo 
        chk_edit_box : "0",
        vProc_gb : "",
        gDate : '',//this.gfn_getBusyDate(),
        gUser_group_cd : '',//this.gfn_userinfo("userGrpTc"),
        
        lv_acct_no_list : [], // 비밀번호 초기화 계좌
        pbpr_data : '', // 사고변경신고서 전자서식 파라미터
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_init
       * 설명       : 초기화
       ******************************************************************************/
      fn_init() {
        console.log('초기화');
        //버튼 제어
        // this.Ds_cu1610_pbpr_txt.clearData();
        // this.Ds_cu1610_detail.clearData();
        this.ds_head = [];
        // this.ds_head.push({
        //   depo_fund_cd : 100000
        //   });
        
        this.ds_detail.cust_no = '';
        this.ds_detail.cust_nm = '';
        
        this.gDate = this.lv_basInfo.busyDate;
        this.gUser_group_cd = this.lv_basInfo.userGrpTc;
        console.log('초기화 시 userInfo', this.lv_basInfo);
        // 고객정보 초기화
        this.$refs.fsCustInfo.fn_Init()
        //to do...
        // application.gv_adm_eno = "";
        // application.gv_adm_seq_no = "";
        this.ds_detail = [];
      },

      /******************************************************************************
       * Function명 : fn_searchList
       * 설명       : 조회 
       ******************************************************************************/
      fn_searchList() {
        console.log('조회');
        this.vProc_gb = "S";
        var vCust_no = this.ds_head.cust_no;
        if(FSCommUtil.gfn_length(vCust_no) != 7) {
          let t_popupObj = {content: '고객번호를 확인하여 주십시오.'}
          this.fn_AlertPopup(0, t_popupObj)
          return;
        }
        this.ds_head.proc_gb =  "S";

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570241_S'
        this.eaiCommObj.params = {
          cust_nm : this.ds_head.cust_nm, // 고객명, Length : 150
          cust_no : this.ds_head.cust_no, // 고객번호, Length : 7
          depo_fund_cd : "100000",//this.ds_head.depo_fund_cd, // 예수금 매체, Length : 12
          proc_gb : this.ds_head.proc_gb // 업무구분, Length : 1
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_select_callback)
      },
      
      /******************************************************************************
       * Function명 : fn_select_callback
       * 설명       : 조회 콜백
       ******************************************************************************/
      fn_select_callback(response){
        console.log('조회 callback ', response);
        var data = response.data.cu161000_O_00VO;
        this.ds_detail = data 
      },

      /******************************************************************************
       * Function명 : fn_errCntClear
       * 설명       : 오류횟수초기화 버튼클릭 처리 
       ******************************************************************************/
      fn_errCntClear(){
        this.vProc_gb = "U";
	
        var vCust_no = this.ds_head.cust_no;
        if(FSCommUtil.gfn_length(vCust_no) != 7) {
          let t_popupObj = {content: '고객번호를 확인하여 주십시오.'}
          this.fn_AlertPopup(0, t_popupObj)
          this.$refs['custNo'].focus
          return;
        }
        
        var vRowCnt = FSCommUtil.gfn_length(this.ds_detail)
        if(vRowCnt == 0) {
          let t_popupObj = {content: '비밀번호 오류횟수 삭제 대상이 없습니다.'}
          this.fn_AlertPopup(0, t_popupObj)
          return;
        }

        var vCheckErrCnt = 0;
        
        let lv_vm = this;
        this.ds_detail.forEach(item => {
          if(item.pwd_err_cnt > 2){
            lv_vm.lv_acct_no_list.push(item)
            vCheckErrCnt ++;
          }
        })

        if(vCheckErrCnt == 0) {
          let t_popupObj = {content: '비밀번호오류횟수가 3회 이상인건이 없습니다.'}
          this.fn_AlertPopup(0, t_popupObj)
          return;
        }

        this.ds_head.proc_gb = this.vProc_gb;
        this.ds_head.sup_id = '';// to do ... application.gv_adm_eno;
        this.ds_head.aprv_sno = ''; //to do... application.gv_adm_seq_no;
        
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570242_S'
        this.eaiCommObj.params = {
          cust_nm : this.ds_head.cust_nm, // 고객명, Length : 150
          cust_no : this.ds_head.cust_no, // 고객번호, Length : 7
          depo_fund_cd : "100000",//this.ds_head.depo_fund_cd, // 예수금 매체, Length : 12
          proc_gb : this.ds_head.proc_gb // 업무구분, Length : 1
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_admission_call_back)
        
      },

      /******************************************************************************
       * Function명 : fn_admission_call_back
       * 설명       : 오류횟수초기화 버튼클릭 콜백 
       ******************************************************************************/
      fn_admission_call_back(response){
        console.log('오류횟수 초기화 callback ', response)
        if( response.data.error_msg == '809902'){
          this.ds_detail = response.data.cu161000_O_00VO
        }
        this.pbpr_data = response.data.passbookVO[0].pbpr_data
        let t_msg = FSMessageUtil.commMessage([])[response.data.error_msg]
        let t_popupObj = {
          confirm: true,
          content: [t_msg],
          confirmFunc: this.fn_ReportPrint,
          confirmData: {
            bfcer_cust_no : this.ds_head.cust_no
          },
        }
        this.fn_AlertPopup(9, t_popupObj)

        // this.fn_ReportPrint(response.data.passbookVO[0]);
      },
      /******************************************************************************
       * Function명 : fn_ReportPrint
       * 설명       : 전자서식 호출
       ******************************************************************************/
      async fn_ReportPrint () {
        console.log('fn_ReportPrint....')

        let formList = []
        let FS000020 = {formId: 'FS000020'}

        let searchData = {
          pbpr_data : this.pbpr_data,
        }

        var t_acct_no = ''
        
        this.lv_acct_no_list.forEach(item =>{
          t_acct_no += (item.pbk_no).substr(0, 7) + '-' + (item.pbk_no).substr(7) + ','
        })

        t_acct_no = t_acct_no.slice(0, -1)

        console.log('t_acct_no', t_acct_no);
        

        FS000020.mappingData = {
          acct_no : t_acct_no
        }
        
        FS000020.params = {
          reportParam: JSON.stringify(searchData),
          reportUrl: 'reportCU161000_Info',
        }

        formList.push(FS000020)

        FSInfoUtil.commReportInfo(this, formList)
      },

      /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type) : 0: 우편번호 / 1: MDG/CDD정보비교 / 2: 고객정보 / 3: 직원번호(직원검색) / 4: 마케팅 동의여부 / 5: 고객ID선택
       ******************************************************************************/
      fn_OpenPopup(type, flag) {
        console.log('팝업 오픈');
        this.$refs.popup308.fn_Open()
      },

      /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type) : 0 고객정보 
       ******************************************************************************/
      fn_Popup_CallBack(type, pData) {
        console.log(pData)
        let t_popupObj = {}
        this.ds_head.cust_no = pData;
        if(pData>0){

          //to do...
          // if(this.ds_sy100500_custinfo.getColumn(0,"fnd_cust_nm")==""){
          //   this.gfn_getMessage("Alert","해당 고객은 당사 거래 종료로 인한 개인정보 접근제한 고객입니다.");
          //   this.div_search.Ed_cust_no.set_value("");
          //   return;
          // }
          // this.div_search.Ed_cust_nm.set_value(this.ds_sy100500_custinfo.getColumn(0,"fnd_cust_nm"));
          // this.div_custInfo.cust_no = this.div_search.Ed_cust_no.value;
          // this.div_custInfo.fn_custinfo_search();
        }
      },
      
      /******************************************************************************
        * Function명 : fn_AlertPopup
        * 설명       : alert popup 호출(공통)
        ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        if( !FSCommUtil.gfn_isNull(pPopupObj) ) {

          // 공통 얼럿 팝업 오브젝트 초기화
          FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
        }
        this.$refs.alertPopup.fn_Open()
      },

      /******************************************************************************
       * Function명 : fn_IsActive
       * 설명       : 고객정보 펼치기/접기
       ******************************************************************************/
      fn_IsActive() {
        this.isActive = !this.isActive;
      },

      /******************************************************************************
       * Function명 : fn_acctNoDashFormat
       * 설명       : 계좌번호 처리  
       ******************************************************************************/
      fn_acctNoDashFormat(acctNo){
        acctNo = FSCommUtil.gfn_substr(acctNo, 0, 7) + "-" + FSCommUtil.gfn_substr(acctNo, 7, 3) 
        return acctNo;
      },

      /******************************************************************************
       * Function명 : fn_CustInfo, fn_SetEdCustName
       * 설명       : 고객정보조회
       ******************************************************************************/
      fn_CustInfo(event) {
        console.log('fn_CustInfo.....', event)
        if( event.key == 'Delete' || event.key == 'Backspace' ) {
          this.ds_head.cust_nm = '';
          // 고객정보 펼치기 정보 초기화
          this.$refs.fsCustInfo.fn_Init()
        }
        if(FSCommUtil.gfn_length(this.ds_head.cust_no) < 7){
          return;
        }
        this.pCustInfoObj.custNo = FSCommUtil.gfn_substr(this.ds_head.cust_no, 0, 7)
        this.$refs.fsCustInfo.fn_CustInfoSearch()
      },

      fn_SetEdCustName(val) {
        // 고객명
        this.ds_head.cust_nm = val
      },

    }
    
  }
</script>
<style scoped>
</style>